<template>
  <div class="switcher">
    <input id="switcher" type="checkbox" class="switch_1" v-model="isChecked">
    <label v-if="labelText" for="switcher">{{ labelText }}</label>
  </div>
</template>

<script>
export default {
  name: 'Switcher',
  props: {
    value: Boolean,
    text: String
  },
  data () {
    return {
      isChecked: this.value || false,
      labelText: this.text || null
    }
  },
  watch: {
    isChecked: function (newValue, oldValue) {
      this.$emit('updateTable', this.isChecked)
    }
  }
}
</script>

<style lang="stylus" scoped>
.box_1
  background #eee

input[type="checkbox"].switch_1
  font-size 20px
  -webkit-appearance none
  -moz-appearance none
  appearance none
  width 3em
  height 1.5em
  background #ddd
  border-radius 3em
  position relative
  cursor pointer
  outline none
  -webkit-transition all .2s ease-in-out
  transition all .2s ease-in-out

  &:checked
    background #FF8000

    &:after
      left calc(100% - 1.5em)

  &:after
    position absolute
    content ""
    width 1.5em
    height 1.5em
    border-radius 50%
    background #fff
    -webkit-box-shadow 0 0 .25em rgba(0,0,0,.3)
    box-shadow 0 0 .25em rgba(0,0,0,.3)
    -webkit-transform scale(.7)
    transform scale(.7)
    left 0
    -webkit-transition all .2s ease-in-out
    transition all .2s ease-in-out

</style>
